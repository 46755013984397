import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ROUTES } from '../../api/routes';
import styles from '../../pages/my-deals/my-deals.module.scss';
import bulletIcon from '../../assets/icons/bullet-point.png';
import CircularArrow from '../../components/common/circular-arrow/circular-arrow';
import {
  DealInterface,
  DealStatusEnum,
  SaleInterface,
} from '../../definitions/interfaces/deals.interface';

interface DealCardProps {
  deal: DealInterface;
  status:
    | DealStatusEnum.LEAD_SUBMITTED
    | DealStatusEnum.LEAD_VERIFIED
    | DealStatusEnum.DEAL_CLOSED
    | DealStatusEnum.SUBMITTED_INVOICE
    | DealStatusEnum.INVOICE_APPROVED
    | DealStatusEnum.LEAD_RESERVED
    | DealStatusEnum
    | null
    | undefined;
  duplicateDeal?: boolean;
  pastDeal?: boolean;
  sales?: SaleInterface[];
}

export function DealCard(props: Readonly<DealCardProps>) {
  const multipleSalesDeal = props.sales && props.sales?.length > 1;

  return (
    <Link
      to={ROUTES['DealDetails'].getPath?.(
        props.duplicateDeal
          ? props.deal.lead_history_source_id
          : props.deal.lead_source_id,
        props.deal.country_id,
        props.duplicateDeal ? '1' : '0',
        props.deal.broker.id
      )}
      className={`card ${styles['deal']}`}
    >
      <div className={styles['info']}>
        <p className={styles['label']}>
          Interested Prospect{' '}
          {props.duplicateDeal ? <span>(duplicated deal)</span> : null}
        </p>
        <div className={styles['value']}>{props.deal.name}</div>
      </div>
      <div className={styles['info']}>
        <p className={styles['label']}>Phone Number</p>
        <div className={styles['value']}>{props.deal.phone}</div>
      </div>
      <div className={styles['info']}>
        <p className={styles['label']}>Deal Status</p>
        <div className={styles['value']}>
          <img src={bulletIcon} alt="" role="presentation" />
          {props.status?.replace(/_/g, ' ')}{' '}
          {multipleSalesDeal ? `+ ${props.sales.length - 1} More` : ''}
          {multipleSalesDeal ? (
            <div className={styles['value--hovered']}>
              {props.sales.map((sale) => (
                <p key={uuidv4()}>
                  {' '}
                  {sale.unit?.name ?? 'no unit name'}:{' '}
                  {sale.deal_status?.replace(/_/g, ' ')}
                </p>
              ))}
            </div>
          ) : null}
        </div>
      </div>
      <CircularArrow />
    </Link>
  );
}

export default DealCard;
