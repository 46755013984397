import {
  Payload,
  UnitDetails as UnitDetailsInterface,
} from '@orascom/api-interfaces';
import { Link, useLocation } from 'react-router-dom';
import Numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import saveIcon from '../../assets/icons/save.svg';
import savedIcon from '../../assets/icons/saved.svg';
import areaIcon from '../../assets/icons/area.svg';
import styles from './unit-info.module.scss';
import { MONTENEGRO_UNITS_BATHROOMS } from '../../definitions/consts/units';
import { useContext } from 'react';
import {
  USER_CONTEXT,
  CompareButton,
  useSaveUnit,
  useCompareUnitsContext,
} from '@orascom/broker-sales-man-common-components';
import { CommonEventParameters } from '@orascom/utils';

export interface UnitDetailsProps {
  unit: UnitDetailsInterface;
  navigateToUnitSpecs: () => void;
  addSavedUnit(unitId: number): Promise<Payload<void>>;
  deleteSavedUnit(unitId: number): Promise<Payload<void>>;
  analyticsUnitSubmitInterestCustomEvent?(params: CommonEventParameters): void;
  analyticsUnitBrochureCustomEvent?(params: CommonEventParameters): void;
  analyticsUnitSpecsCustomEvent?(params: CommonEventParameters): void;
  comparePropertiesPath: string;
  unitDealGetPath?: Function;
}

export function UnitInfo(props: Readonly<UnitDetailsProps>) {
  const { t } = useTranslation();
  const userContext = useContext(USER_CONTEXT);
  const location = useLocation();
  const { unit, navigateToUnitSpecs } = props;
  const { saveUnit, isSaved, isSaving } = useSaveUnit(
    unit,
    props.addSavedUnit,
    props.deleteSavedUnit
  );

  let unitBathroomsNumber: number | string = unit.bathrooms;

  if (
    unitBathroomsNumber &&
    unit.project?.destination?.country?.slug === 'montenegro'
  ) {
    unitBathroomsNumber = MONTENEGRO_UNITS_BATHROOMS[unitBathroomsNumber];
  }

  const { compareUnitsIds, addUnitToCompare, removeUnitFromCompare } =
    useCompareUnitsContext();

  const isInComparison = compareUnitsIds?.includes(unit.id);

  const analyticsEventParams: CommonEventParameters = {
    userId: userContext.user?.id.toString(),
    timestamp: Date.now().toString(),
    portal: 'Broker',
    pageName: location.pathname,
  };
  return (
    <div className={styles['wrapper']}>
      <div className={styles['details']}>
        <div className={styles['title']}>
          <div>
            <h5>
              {unit?.project?.name} | {unit?.project?.destination?.name}
            </h5>
            <h1>{unit?.unit_type?.name}</h1>
          </div>
          <button
            className={styles['save-unit']}
            onClick={() => saveUnit()}
            disabled={isSaving}
          >
            <img
              src={isSaved ? savedIcon : saveIcon}
              alt=""
              role="presentation"
            />
          </button>
        </div>
        <div className={styles['info']}>
          <div>
            <h5 className={styles['key']}>{t('unitPrice')}</h5>
            <p className={styles['value']}>
              {`${Numeral(unit?.price).format('0,0')} ${unit?.currency}`}
            </p>
          </div>
          {unit?.total_area ? (
            <div>
              <h5 className={styles['key']}>{t('totalLandArea')}</h5>
              <p className={styles['value']}>
                {unit?.total_area} {t('mSquared')}
              </p>
            </div>
          ) : null}
          {unit?.unit_number ? (
            <div>
              <h5 className={styles['key']}>{t('unitName')}</h5>
              <p className={styles['value']}>{unit?.name}</p>
            </div>
          ) : null}
          {unit?.net_area ? (
            <div>
              <h5 className={styles['key']}>{t('netArea')}</h5>
              <p className={styles['value']}>
                {unit?.net_area} {t('mSquared')}
              </p>
            </div>
          ) : null}
          {unit?.bedrooms ? (
            <div>
              <h5 className={styles['key']}>{t('noOfBedrooms')}</h5>
              <p className={styles['value']}>{unit?.bedrooms}</p>
            </div>
          ) : null}
          {unit?.built_up_area ? (
            <div>
              <h5 className={styles['key']}>{t('builtUpArea')}</h5>
              <p className={styles['value']}>
                {unit?.built_up_area} {t('mSquared')}
              </p>
            </div>
          ) : null}
          {unit?.finishing_status ? (
            <div>
              <h5 className={styles['key']}>{t('finishingOption')}</h5>
              <p className={styles['value']}>{unit?.finishing_status}</p>
            </div>
          ) : null}
          {unitBathroomsNumber ? (
            <div>
              <h5 className={styles['key']}>{t('noOfBathrooms')}</h5>
              <p className={styles['value']}>{unitBathroomsNumber}</p>
            </div>
          ) : null}
          {unit?.garden_area ? (
            <div>
              <h5 className={styles['key']}>{t('gardenArea')}</h5>
              <p className={styles['value']}>
                {unit?.garden_area} {t('mSquared')}
              </p>
            </div>
          ) : null}
          {unit?.construction_status ? (
            <div>
              <h5 className={styles['key']}>{t('buildingStatus')}</h5>
              <p className={styles['value']}>
                {unit?.construction_status?.replace(/_/g, ' ')}
              </p>
            </div>
          ) : null}
        </div>
        <button
          className={styles['specs-anchor']}
          onClick={() => {
            navigateToUnitSpecs();
            props.analyticsUnitSpecsCustomEvent?.(analyticsEventParams);
          }}
        >
          <img src={areaIcon} alt="" role="presentation" />
          <span className="anchor">{t('seeFullUnitSpecs')}</span>
        </button>
        <div className={styles['button-wrapper']}>
          <Link
            to={props.unitDealGetPath?.(unit?.id)}
            className={`btn btn--navy ${styles['submit-interest']}`}
            onClick={() =>
              props.analyticsUnitSubmitInterestCustomEvent?.(
                analyticsEventParams
              )
            }
          >
            {t('submitInterest')}
          </Link>
          <a
            href={
              unit?.brochure?.length ? unit?.brochure : unit.project.brochure
            }
            download
            target="_blank"
            rel="noreferrer"
            className="anchor"
            onClick={() =>
              props.analyticsUnitBrochureCustomEvent?.(analyticsEventParams)
            }
          >
            {t('downloadBrochure')}
          </a>
        </div>
        <button
          className={styles['compare-unit']}
          onClick={(e) => e.stopPropagation()}
        >
          <input
            type="checkbox"
            id={`add-unit-${unit.id}`}
            name="add-unit"
            checked={isInComparison}
            onChange={() => {
              if (isInComparison) removeUnitFromCompare(unit.id);
              else addUnitToCompare(unit.id);
            }}
          />
          <label htmlFor={`add-unit-${unit.id}`}>
            {isInComparison ? t('addedToComparison') : t('addToCompare')}
          </label>
        </button>
      </div>
      <CompareButton comparePropertiesPath={props.comparePropertiesPath} />
    </div>
  );
}

export default UnitInfo;
