/**
 * @author Salma Hefnawy
 * @date 2022-10-02
 * @description implementation of orascom-broker-app endpoints in the api.
 * @filename endpoints.ts
 */

/**
 * set of endpoints in the API.
 */
const endpointsPrefix = process.env['NX__BROKER_ENDPOINT_PREFIX'] ?? '';
const salesManagerEndpointsPrefix =
  process.env['NX__SALES_MANAGER_ENDPOINT_PREFIX'] ?? '';

export const ENDPOINTS = {
  getUnits: {
    url: (filters?: string): string =>
      `${endpointsPrefix}/units${filters ?? ''}`,
    method: 'GET',
  },

  compareUnits: {
    path: `${endpointsPrefix}/units/compare`,
    method: 'GET',
  },

  getUnitDetails: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}`,
    method: 'GET',
  },

  getUnitPaymentTerms: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}/payment-terms`,
    method: 'GET',
  },

  login: {
    url: `/login`,
    method: 'POST',
  },

  logout: {
    url: `${endpointsPrefix}/logout`,
    method: 'POST',
  },
  logoutSalesManager: {
    url: `${salesManagerEndpointsPrefix}/logout`,
    method: 'POST',
  },

  getUserData: {
    url: `/user`,
    method: 'GET',
  },

  setPassword: {
    url: `${endpointsPrefix}/reset-password`,
    method: 'POST',
  },

  forgetPassword: {
    url: `${endpointsPrefix}/forgot-password`,
    method: 'POST',
  },

  getDocuments: {
    url: `${endpointsPrefix}/documents`,
    method: 'GET',
  },

  getFilters: {
    url: `${endpointsPrefix}/filters`,
    method: 'GET',
  },
  listDestinations: {
    url: `${endpointsPrefix}/destinations`,
    method: 'GET',
  },
  getDestinationInfo: {
    url: (destinationSlug: string) =>
      `${endpointsPrefix}/destinations/${destinationSlug}`,
    method: 'GET',
  },

  getNewsAndEvents: {
    url: `${endpointsPrefix}/news`,
    method: 'GET',
  },

  getNewsDetails: {
    url: (newsSlug: string) => `${endpointsPrefix}/news/${newsSlug}`,
    method: 'GET',
  },

  getDestinationFacilities: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/facilities`,
    method: 'GET',
  },

  getDestinationLocations: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/locations`,
    method: 'GET',
  },

  getDestinationEducation: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/educational-hubs`,
    method: 'GET',
  },

  getDestinationProjects: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/projects`,
    method: 'GET',
  },

  getDestinationFAQ: {
    url: (destinationSlug: string): string =>
      `${endpointsPrefix}/destinations/${destinationSlug}/faqs`,
    method: 'GET',
  },

  getRecentUpdates: {
    url: `${endpointsPrefix}/user/notifications`,
    method: 'GET',
  },

  getSavedUnits: {
    url: `${endpointsPrefix}/user/saved-units`,
    method: 'GET',
  },

  addSavedUnit: {
    url: (unitId: number): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'POST',
  },

  deleteSavedUnit: {
    url: (unitId: number): string =>
      `${endpointsPrefix}/user/saved-units/${unitId}`,
    method: 'DELETE',
  },

  readNotifications: {
    url: `${endpointsPrefix}/user/notifications/read`,
    method: 'POST',
  },

  createLead: {
    url: `${endpointsPrefix}/leads`,
    method: 'POST',
  },
  createMeeting: {
    url: (dealId: string): string =>
      `${endpointsPrefix}/leads/${dealId}/meetings`,
    method: 'POST',
  },
  getMeetings: {
    url: `${endpointsPrefix}/meetings`,
    method: 'GET',
  },
  getLeadMeetings: {
    url: (dealId: string): string =>
      `${endpointsPrefix}/leads/${dealId}/meetings`,
    method: 'GET',
  },
  getLeads: {
    url: `${endpointsPrefix}/leads`,
    method: 'GET',
  },
  getDuplicateLeads: {
    url: `${endpointsPrefix}/leads-duplicate`,
    method: 'GET',
  },
  getLeadById: {
    url: (dealId: string): string => `${endpointsPrefix}/leads/${dealId}`,
    method: 'GET',
  },
  submitInvoice: {
    url: (dealId: string): string =>
      `${endpointsPrefix}/leads/${dealId}/sales_invoices`,
    method: 'POST',
  },
  getSaleInvoice: {
    url: (dealId: string): string =>
      `${endpointsPrefix}/leads/${dealId}/sales_invoices`,
    method: 'GET',
  },
  shareReservationLink: {
    url: (unitId: string): string =>
      `${endpointsPrefix}/units/${unitId}/share-reservation-link`,
    method: 'POST',
  },
  getAnalyticsLeadCount: {
    url: `${endpointsPrefix}/analytics/leads/lifecycle-counts`,
    method: 'GET',
  },
  getAnalyticsFilterDestinations: {
    url: `${endpointsPrefix}/analytics/filters/destinations`,
    method: 'GET',
  },
  getAnalyticsFilterUnitTypes: {
    url: `${endpointsPrefix}/analytics/filters/unit-types`,
    method: 'GET',
  },
  getAnalyticsFilterAssignedBrokers: {
    url: `${endpointsPrefix}/analytics/filters/assigned-brokers`,
    method: 'GET',
  },
  getAnalyticsSalesCountPerDestination: {
    url: `${endpointsPrefix}/analytics/sales/count-per-destination`,
    method: 'GET',
  },
  getAnalyticsSalesCountPerUnitType: {
    url: `${endpointsPrefix}/analytics/sales/count-per-unit-types-breakdown`,
    method: 'GET',
  },
  getAnalyticsCommissions: {
    url: `${endpointsPrefix}/analytics/commissions`,
    method: 'GET',
  },
  getAnalyticsRevenueInsights: {
    url: `${endpointsPrefix}/analytics/revenue-insights`,
    method: 'GET',
  },
  getAnalyticsAgentsRanking: {
    url: `${endpointsPrefix}/analytics/brokers-ranking`,
    method: 'GET',
  },
  getAnalyticsCommissionsRanking: {
    url: `${endpointsPrefix}/analytics/commissions/statistics`,
    method: 'GET',
  },
  getAnalyticsCompanyRanking: {
    url: `${endpointsPrefix}/analytics/company-ranking`,
    method: 'GET',
  },
  downloadOmanSalesOffer: {
    url: (unitId: number) => `${endpointsPrefix}/units/${unitId}/sales-offer`,
    method: 'GET',
  },
  getAnalyticsCommissionsSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/brokers-commissions`,
    method: 'GET',
  },
  getAnalyticsCommissionsDetailsSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/commissions`,
    method: 'GET',
  },
  getAnalyticsLeadCountSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/leads/lifecycle-counts`,
    method: 'GET',
  },
  getAnalyticsInternationalSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/sales/international`,
    method: 'GET',
  },
  getAnalyticsFilterDestinationsSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/filters/destinations`,
    method: 'GET',
  },
  getAnalyticsFilterUnitTypeSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/filters/unit-types`,
    method: 'GET',
  },
  getAnalyticsFilterBrokerageSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/filters/brokerage-companies`,
    method: 'GET',
  },
  getAnalyticsSalesCountPerDestinationSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/sales/count-per-destination`,
    method: 'GET',
  },
  getAnalyticsRevenueInsightsSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/revenue-insights`,
    method: 'GET',
  },
  getAnalyticsBrokerRankingsSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/brokers-ranking`,
    method: 'GET',
  },
  getAnalyticsBrokeragesRankingSalesManager: {
    url: `${salesManagerEndpointsPrefix}/analytics/brokerages-ranking`,
    method: 'GET',
  },
  getAnalyticsSalesManagerRanking: {
    url: `${salesManagerEndpointsPrefix}/analytics/sales-managers-ranking`,
    method: 'GET',
  },
  getAnalyticsFilterSalesManagers: {
    url: `${salesManagerEndpointsPrefix}/analytics/filters/assigned-sales-managers-titles`,
    method: 'GET',
  },
  getAnalyticsBrokerCommissions: {
    url: `${salesManagerEndpointsPrefix}/analytics/brokers-commissions/export`,
    method: 'GET',
  },
};
