import { useTranslation } from 'react-i18next';
import styles from './similar-units.module.scss';
import { useEffect, useState } from 'react';
import {
  PaginationPayload,
  Payload,
  UnitInterface,
} from '@orascom/api-interfaces';
import { toast } from 'react-toastify';
import { CardsSlider, Loader } from '@orascom/common-components';
import { PropertyCard } from '@orascom/broker-sales-man-common-components';
import { Link } from 'react-router-dom';
import arrowIcon from '../../assets/icons/left-arrow.svg';
export interface SimilarUnitsProps {
  unitId: number;
  price: number;
  designType: string;
  getSimilarUnits(
    unitId: string,
    price: number,
    designType: string
  ): Promise<PaginationPayload<UnitInterface[]>>;
  similarUnitsGetPath?: Function;
  unitDetailsGetPath?: Function;
  addSavedUnit(unitId: number): Promise<Payload<void>>;
  deleteSavedUnit(unitId: number): Promise<Payload<void>>;
}
export function SimilarUnits(props: Readonly<SimilarUnitsProps>) {
  const { t } = useTranslation();
  const [units, setUnits] = useState<PaginationPayload<UnitInterface[]>>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props
      .getSimilarUnits(props.unitId.toString(), props.price, props.designType)
      .then((res) => {
        setUnits(res);
      })
      .catch((err) => {
        toast.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [props.unitId]);
  if (loading) {
    return <Loader />;
  }
  if (units?.data.length === 0) {
    return null;
  }
  return (
    <div className={`${styles['similar-units-container']} card`}>
      <div className={styles['similar-units-container-heading']}>
        <h2 className={styles['similar-units-container-heading__title']}>
          {t('similarUnits')}
        </h2>
        <Link
          to={props.similarUnitsGetPath?.(props.unitId.toString(), props.price)}
          className={styles['view-all']}
        >
          <span className="anchor">{t('viewAll')}</span>
          <img
            className={styles['arrow-right']}
            src={arrowIcon}
            alt=""
            role="presentation"
          />
        </Link>
      </div>

      {units && (
        <div className={styles['units-container']}>
          <CardsSlider className="similar-units-card">
            {units.data.map((unit) => (
              <PropertyCard
                key={unit.id}
                unit={unit}
                handleRemove={undefined}
                responsiveImages
                unitDetailsPath={props.unitDetailsGetPath}
                addSavedUnit={props.addSavedUnit}
                deleteSavedUnit={props.deleteSavedUnit}
                portal="broker"
              />
            ))}
          </CardsSlider>
        </div>
      )}
    </div>
  );
}
export default SimilarUnits;
