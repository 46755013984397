import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectDropdown, RangeSlider } from '@orascom/common-components';
import {
  OptionValue,
  UnitPaymentTerms,
  InstallmentFrequencyMapper,
  CountriesEnum,
} from '@orascom/api-interfaces';
import styles from './unit-payment-calculator.module.scss';
import {
  extractUniqueDurations,
  usePaymentPlanCalculator,
} from '@orascom/utils';
import { useParams } from 'react-router-dom';
import Numeral from 'numeral';
import { Unit as UnitUtils } from '../../utils/unit';

interface UnitPaymentCalculatorProps {
  saveButton: React.ReactNode;
  hideDetails?: boolean;
  comparePage?: boolean;
  unitCountry: CountriesEnum | undefined;
  unitId?: number;
}
export function UnitPaymentCalculator(
  props: Readonly<UnitPaymentCalculatorProps>
) {
  const [loading, setLoading] = useState(true);
  const [paymentTerms, setPaymentTerms] = useState<UnitPaymentTerms[]>([]);
  const [error, setError] = useState(false);
  const unitId =
    useParams<{ unitId: string }>().unitId ?? props.unitId?.toString();
  const {
    onSelectDownPayment,
    onSelectDuration,
    selectedPaymentTerms,
    setSelectedPaymentTerms,
    showInstallmentAmount,
    hideUnitInstallments,
  } = usePaymentPlanCalculator();

  const { t } = useTranslation();

  useEffect(() => {
    if (!unitId) {
      return;
    }
    UnitUtils.getUnitPaymentTerms(Number(unitId))
      .then((terms) => {
        setPaymentTerms(terms);

        setSelectedPaymentTerms(terms[0]);
      })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  let downPaymentOptions: OptionValue[] = [];

  const selectedDownPayment: {
    value: number;
    label: string;
  } | null = selectedPaymentTerms
    ? {
        label: `${selectedPaymentTerms.downpayment_percent}%`,
        value: selectedPaymentTerms.downpayment_percent,
      }
    : null;

  if (paymentTerms?.length > 0) {
    downPaymentOptions = paymentTerms.reduce((options: OptionValue[], term) => {
      if (!options.find((opt) => opt.value === term.downpayment_percent)) {
        options.push({
          label: `${term.downpayment_percent}%`,
          value: term.downpayment_percent,
        });
      }
      return options;
    }, []);
  }

  const installmentDurations = extractUniqueDurations(paymentTerms);

  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return (
      <h2 className="orascom__title">No payment plan found for this unit</h2>
    );
  }

  const displayedPaymentDetails = { ...selectedPaymentTerms };

  const comparePageCalculatorItems =
    props.unitCountry === CountriesEnum.OMAN ? (
      <div className={styles['details-item']}>
        {showInstallmentAmount(displayedPaymentDetails) && (
          <div>
            <label>
              {t('installments')} .{' '}
              <small>
                {displayedPaymentDetails.total_number_of_installments}{' '}
                {t('installments')}
              </small>
            </label>
            <p>
              {`${Numeral(displayedPaymentDetails?.installment_amount).format(
                '0,0'
              )} ${displayedPaymentDetails?.currency} `}
              <small>
                /{' '}
                {
                  InstallmentFrequencyMapper[
                    displayedPaymentDetails?.installment_frequency as number
                  ]
                }
              </small>
            </p>
          </div>
        )}
      </div>
    ) : (
      <div className={styles['installments']}>
        {hideUnitInstallments(displayedPaymentDetails, props.unitCountry) || (
          <>
            <div>
              <label>
                {t('beforeDelivery')} .{' '}
                <small>
                  {
                    displayedPaymentDetails.total_number_of_installments_before_delivery
                  }{' '}
                  {t('installments')}
                </small>
              </label>
              <p>
                {`${Numeral(
                  displayedPaymentDetails?.unit_installment_before_delivery_per_frequency
                ).format('0,0')} ${displayedPaymentDetails?.currency} `}
                <small>
                  /{' '}
                  {
                    InstallmentFrequencyMapper[
                      displayedPaymentDetails?.installment_frequency as number
                    ]
                  }
                </small>
              </p>
            </div>

            <div>
              <label>
                {t('afterDelivery')} .{' '}
                <small>
                  {
                    displayedPaymentDetails.total_number_of_installments_after_delivery
                  }{' '}
                  {t('installments')}
                </small>
              </label>
              <p>
                {Numeral(
                  displayedPaymentDetails?.unit_installment_after_delivery_per_frequency
                ).format('0,0')}{' '}
                {displayedPaymentDetails?.currency}{' '}
                <small>
                  /{' '}
                  {
                    InstallmentFrequencyMapper[
                      displayedPaymentDetails?.installment_frequency as number
                    ]
                  }
                </small>
              </p>
            </div>
          </>
        )}
      </div>
    );

  return (
    <div className="plan">
      <div className={styles['dropdowns']}>
        <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
          <label>
            {t('downpayment')} <small>{t('downpaymentDisclaimer')}</small>
          </label>
          <div className={styles['select-wrapper']}>
            <SelectDropdown
              options={downPaymentOptions}
              placeholder={t('downPayment')}
              onChange={(val) => {
                onSelectDownPayment(val?.value as number, paymentTerms);
              }}
              selectedOption={selectedDownPayment}
            />
            {selectedPaymentTerms && (
              <span className={styles['select-value']}>
                {Numeral(displayedPaymentDetails?.downpayment_amount).format(
                  '0,0'
                )}{' '}
                {displayedPaymentDetails?.currency}
              </span>
            )}
          </div>
        </div>
        <div className={`${styles['dropdown-wrapper']} dropdown-wrapper`}>
          <div className={styles['flex']}>
            <label>{t('installmentDuration')}</label>
            <h5>
              {displayedPaymentDetails?.installment_durationin_years}{' '}
              {t('years')}
            </h5>
          </div>
          <RangeSlider
            className={styles['range']}
            sliderValues={installmentDurations}
            inputValue={selectedPaymentTerms?.installment_durationin_years}
            onChange={(val) => {
              onSelectDuration(val, paymentTerms);
            }}
            sliderWrapperProps={{ className: styles['range-wrapper'] }}
          />
        </div>
        {props.comparePage ? comparePageCalculatorItems : null}
      </div>
    </div>
  );
}

export default UnitPaymentCalculator;
